import React from "react";
import btL from "../btL.png";
import btR from "../btR.png";
import fondoBase from "../wassermaticvendinginnovation.jpg";

import YouTube from "react-youtube";

import "../App.css";
//import Button from "@material-ui/core/Button";



class CompWebPlayer extends React.Component {
  constructor(props) {
    super(props);
    //"http://127.0.0.1/webwassermaticvendinginnovation/index.php/apiViews/getListaBanners",
    this.state = {
      vistaActual: 0, //0 img  banners  , 1 youtube detalles
      urlLista: "https://wassermatic.com.mx/index.php/apiViews/getListaBanners",
      numeroTotal: "0",
      numeroActual: "0",
      tipoArchivoActual: "0",
      urlArchivoActual: fondoBase,
      idYoutubeActual: "",
      urlLink: "#",
      urlRepositorio: "https://wassermatic.com.mx/assets/img/banners/",
      dataLista: [],
      contGlobal: -1,
      timeOutFwd:null,
      timeOutBck:null,
    };
    //bind de los eventois click
    this.fnClickAddProducto = this.fnClickAddProducto.bind(this);
    this.fnCambio = this.fnCambio.bind(this);
    this.fnCambioBack = this.fnCambioBack.bind(this);
    this.fnVerVideo = this.fnVerVideo.bind(this);
    this.fnCerrarVideo = this.fnCerrarVideo.bind(this);
    
  }

  fnClickAddProducto() {
    // alert("Clicked Button");
    this.setState({ vistaActual: 1 });
  }

  componentDidMount() {
    this.fnLista();

    //  alert("hola");
    // setInterval(() => {
    //   console.log('Interval triggered');
    // }, 1000);
  }

  fnVerVideo() {
    clearTimeout(this.state.timeOutFwd);
    clearTimeout(this.state.timeOutBck);
      this.setState({vistaActual:1});    
  }
  fnCerrarVideo() {
    this.setState({vistaActual:0});    
    this.fnCambio();
  }

  fnCambio() {
    console.log("Fwd");
   
  
    var duracionImagen = 10000;
    this.setState({ contGlobal: this.state.contGlobal + 1 });
    //valida si el contador ya se paso
    if (this.state.contGlobal > this.state.dataLista.length - 1) {
      this.setState({ contGlobal: 0 });
    }
    this.setState({
      urlLink: this.state.dataLista[this.state.contGlobal].T_URL_DETALLE,
    });
    this.setState({
      idYoutubeActual: this.state.dataLista[this.state.contGlobal].E_ID_YOUTUBE,
    });
    this.setState({
      urlArchivoActual:
        this.state.urlRepositorio +
        this.state.dataLista[this.state.contGlobal].T_URL_ARCHIVO,
    });
    duracionImagen =
      this.state.dataLista[this.state.contGlobal].T_DURACION_SEG * 1000;
      clearTimeout(this.state.timeOutFwd);
    this.setState({timeOutFwd: setTimeout(
        function () {
          // alert("Cambio");
          this.fnCambio();
          // this.setState({ position: 1 });
          // this.fnCambio();
        }.bind(this),
        duracionImagen
      )})
    /*setTimeout(
      function () {
        // alert("Cambio");
        this.fnCambio();
        // this.setState({ position: 1 });
        // this.fnCambio();
      }.bind(this),
      duracionImagen
    );*/
  }
  fnCambioBack() {
   console.log("Bck");
    var duracionImagen = 10000;
    this.setState({ contGlobal: this.state.contGlobal - 1 });
    //valida si el contador ya se paso
    if (this.state.contGlobal < 0) {
      this.setState({ contGlobal: this.state.dataLista.length - 1 });
    }
    this.setState({
      urlLink: this.state.dataLista[this.state.contGlobal].T_URL_DETALLE,
    });
    this.setState({
      idYoutubeActual: this.state.dataLista[this.state.contGlobal].E_ID_YOUTUBE,
    });
    this.setState({
      urlArchivoActual:
        this.state.urlRepositorio +
        this.state.dataLista[this.state.contGlobal].T_URL_ARCHIVO,
    });
    duracionImagen =this.state.dataLista[this.state.contGlobal].T_DURACION_SEG * 1000;
      clearTimeout(this.state.timeOutBck);
      this.setState({timeOutBck:setTimeout(
      function () {
        // alert("Cambio");
        this.fnCambioBack();
        // this.setState({ position: 1 });
        // this.fnCambio();
      }.bind(this),
      duracionImagen
    )})
  }
  async fnLista() {
    // alert(this.state.urlLista);
    //obtiene sucursales para variable global
    fetch(this.state.urlLista, {
      headers: { "Cache-Control": "no-cache" },
    })
      .then((response) => response.json())
      .then((json) => {
        //alert(json[0].E_ORDEN)
        //  var resultado=JSON.stringify(json);
        // alert(resultado);
        this.setState({ numeroTotal: json.length });
        this.setState({ dataLista: json });
        this.setState({
          urlArchivoActual: this.state.urlRepositorio + json[0].T_URL_ARCHIVO,
        });
        // alert(this.state.urlArchivoActual);
        this.fnCambio();

        // alert(JSON.stringify(json));
      })
      .catch((error) => alert(error))
      .finally(() => {
        // alert(JSON.stringify(this.state.dataSucursales));
        // this.setState({ isLoading: false });
      });
  }

  render() {
    const opts = {
      height: "390",
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };

    let contenidoBtVerVideo = <div><br /></div>;
    let contenidoImg = (
      <div>
        <img src={this.state.urlArchivoActual} className="frameImg" />
      </div>
    );
    if (this.state.urlLink.length > 5) {
      contenidoImg = (
        <div>
          <a href={this.state.urlLink} target="_blank">
            <img src={this.state.urlArchivoActual} className="frameImg" />
          </a>
        </div>
      );
    }
    if (this.state.idYoutubeActual.length > 5) {
      contenidoBtVerVideo = (
        <div>
          <button onClick={this.fnVerVideo} className="btVerVideo">
            Ver video
          </button>
        </div>
      );
    } else {
      contenidoBtVerVideo = <div><br /></div>;
    }

    let contenidosYouTube = <div></div>;
    if (this.state.vistaActual == 1) {
      //"MS3FJ4yJmUQ" id sample
      contenidosYouTube = (
        <div className="panelTube">
          <YouTube
            videoId={this.state.idYoutubeActual}
            opts={opts}
            onReady={this._onReady}
          />          
          <button onClick={this.fnCerrarVideo} className="btCerrar">
            Cerrar
          </button>
        </div>
      );
    }else{
        contenidosYouTube = <div></div>;
    }

    return (
      <div className="fondoFrames">
        <img src={btL} onClick={this.fnCambioBack} className="btLeft" />
        <img src={btR} onClick={this.fnCambio} className="btRight" />
        {contenidoImg}
        {contenidoBtVerVideo}
        {contenidosYouTube}
      </div>
    );
  }
  _onReady(event) {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  }
}
export default CompWebPlayer;
